// React Essentials
import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
// Components
import Icon from "../../components/icon/Icon";

import LogoImage from "../../assets/logos/ModularWideLogo_Inverted.png";

const Brand = ({ asideStatus, setAsideStatus }) => {
  return (
    <div className="brand">
      <div className="brand-logo">
        <h1 className="brand-title ">
          {/* WHERE WE WILL PUT MORPHO LOGO */}
          <Link alt="Morpho" aria-label="Logo" to="/">
            <img alt="modular logo" src={LogoImage} width={125} height={20} />
          </Link>
        </h1>
      </div>
      <button
        aria-label="Toggle Aside"
        className="btn brand-aside-toggle"
        onClick={() => setAsideStatus(!asideStatus)}
        type="button"
      >
        {/* ICON OF OPENING AND CLOSING LEFT NAVIGATION BAR */}
        <Icon className="brand-aside-toggle-close" icon="FirstPage" />
        <Icon className="brand-aside-toggle-open" icon="LastPage" />
      </button>
    </div>
  );
};
// Setting Headers to be required
Brand.propTypes = {
  asideStatus: PropTypes.bool.isRequired,
  setAsideStatus: PropTypes.func.isRequired,
};

export default Brand;
