import * as React from "react";

function SvgArchiveFill(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      className="svg-icon"
      viewBox="0 0 16 16"
      {...props}
    >
      <path d="M12.643 15C13.979 15 15 13.845 15 12.5V5H1v7.5C1 13.845 2.021 15 3.357 15h9.286zM5.5 7h5a.5.5 0 010 1h-5a.5.5 0 010-1zM.8 1a.8.8 0 00-.8.8V3a.8.8 0 00.8.8h14.4A.8.8 0 0016 3V1.8a.8.8 0 00-.8-.8H.8z" />
    </svg>
  );
}

export default SvgArchiveFill;
