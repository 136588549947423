import * as React from "react";

const SvgCustomPaypal = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512.001 512.001"
    style={{
      enableBackground: "new 0 0 512.001 512.001",
    }}
    xmlSpace="preserve"
    width="1em"
    height="1em"
    className="svg-icon"
    {...props}
  >
    <path
      style={{
        fill: "#03a9f4",
      }}
      d="M425.457 117.739a10.664 10.664 0 0 0-10.197-.341 10.667 10.667 0 0 0-5.803 8.384c-.384 3.499-.981 6.997-1.728 10.667-20.885 94.784-62.827 140.885-128.256 140.885h-96a10.666 10.666 0 0 0-10.432 8.533l-32 149.995-5.717 38.187c-3.287 17.365 8.125 34.107 25.489 37.394 1.915.362 3.858.549 5.807.558h64.213c14.718.045 27.55-10 31.04-24.299l25.941-103.701h55.659c65.685 0 111.083-52.373 127.829-147.477 11.054-45.286-7.234-92.668-45.845-118.785z"
    />
    <path
      style={{
        fill: "#283593",
      }}
      d="M405.339 38.017a112.254 112.254 0 0 0-83.2-38.016h-176.64C119.064-.141 96.558 19.2 92.721 45.355L37.873 411.243c-2.627 17.477 9.41 33.774 26.887 36.402 1.586.239 3.189.357 4.793.356h81.92a10.666 10.666 0 0 0 10.432-8.533l30.187-140.8h87.467c75.904 0 126.059-53.056 149.099-157.867.926-4.178 1.638-8.4 2.133-12.651a110.146 110.146 0 0 0-25.452-90.133z"
    />
  </svg>
);

export default SvgCustomPaypal;
