// React Essentials
import React, { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import contents from "../../routes/contentRoutes";
import { withoutAsidePages } from "../../menu";

// const PAGE_404 = lazy(() => import("../../pages/404/Page404"));

const ContentRoutes = () => {
  return (
    <Routes>
      {contents.map((page) => (
        <Route key={page.path} {...page} />
      ))}
      <Route
        path="*"
        element={<Navigate to={withoutAsidePages.page404.path} />}
      />
    </Routes>
  );
};

export default ContentRoutes;
