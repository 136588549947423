import React, { useContext } from "react";
import classNames from "classnames";
import Header, { HeaderLeft } from "../../../layout/Header/Header";
import CommonHeaderRight from "../Headers/CommonHeaderRignt";
import useDarkMode from "../../../hooks/useDarkMode";
import AuthContext from "../../../contexts/AuthContext";
import USERS from "../../../common/data/userDummyData";

const DefaultHeader = () => {
  const { darkModeStatus } = useDarkMode();
  const { user } = useContext(AuthContext);
  return (
    <Header>
      <HeaderLeft>
        <div className="d-flex align-items-center">
          <div className="row g-4">
            <div className="col-md-auto">
              <div
                className={classNames("fs-3", "fw-bold", {
                  "text-dark": !darkModeStatus,
                })}
              >
                Hi, {user?.userData?.username || USERS.JOHN.name}
              </div>
            </div>
          </div>
        </div>
      </HeaderLeft>
      <CommonHeaderRight />
    </Header>
  );
};

export default DefaultHeader;
