// React Essentials
import React, { useContext, useRef } from "react";
import classNames from "classnames";
import { motion } from "framer-motion";
// Divs for Header,Body and Footer
import {
  designerMenu,
  developerMenu,
  adminMenu,
  clientMenu,
  dashboardMenu,
} from "../../menu";
// Components
import Brand from "../Brand/Brand";
import Navigation, { NavigationLine } from "../Navigation/Navigation";
// Context
import ThemeContext from "../../contexts/themeContext";
import User from "./../User/User";
// Hooks
import useAsideTouch from "../../hooks/useAsideTouch";
import AuthContext from "../../contexts/AuthContext";
// import Icon from "../../components/icon/Icon";
// import useDarkMode from "../../hooks/useDarkMode";

export const AsideHeader = ({ children, className, ...props }) => {
  return (
    <div className={classNames("aside-head", className)} {...props}>
      {children}
    </div>
  );
};

export const AsideBody = ({ children, className, ...props }) => {
  return (
    <div className={classNames("aside-body", className)} {...props}>
      {children}
    </div>
  );
};

export const AsideFooter = ({ children, className, ...props }) => {
  return (
    <div className={classNames("aside-foot", className)} {...props}>
      {children}
    </div>
  );
};

const Aside = ({ editor = false, position = "left", children }) => {
  const { asideStatus, setAsideStatus } = useContext(ThemeContext);
  const { asideStyle, touchStatus, hasTouchButton, asideWidthWithSpace, x } =
    useAsideTouch();
  const constraintsRef = useRef(null);
  const isModernDesign = process.env.REACT_APP_MODERN_DESGIN === "true";
  const { user } = useContext(AuthContext);
  // const { darkModeStatus } = useDarkMode();
  //   const { t } = useTranslation(["translation", "menu"]);

  const handleUserMenu = () => {
    switch (user?.userData?.role?.role) {
      case "Designer":
        return designerMenu;
      case "Developer":
        return developerMenu;
      case "Client":
        return clientMenu;
      case "Admin":
        return adminMenu;
      default:
        return {};
    }
  };

  return (
    <>
      <motion.aside
        className={classNames(
          "aside",
          { open: asideStatus },
          { editor: editor },
          {
            "aside-touch-bar": hasTouchButton && isModernDesign,
            "aside-touch-bar-close":
              !touchStatus && hasTouchButton && isModernDesign,
            "aside-touch-bar-open":
              touchStatus && hasTouchButton && isModernDesign,
          }
        )}
        style={position === "left" ? asideStyle : { right: 0, left: "auto" }}
      >
        {editor ? (
          children
        ) : (
          <>
            <AsideHeader>
              <Brand
                asideStatus={asideStatus}
                setAsideStatus={setAsideStatus}
              />
            </AsideHeader>
            <AsideBody>
              <Navigation id="aside-dashboard" menu={dashboardMenu} />
              <NavigationLine />
              {user && (
                <>
                  <Navigation
                    id="aside-demo-pages"
                    menu={user && handleUserMenu()}
                  />
                  <NavigationLine />
                </>
              )}
            </AsideBody>
            <AsideFooter>
              <User />
            </AsideFooter>
          </>
        )}
      </motion.aside>
      {/* for the drag left navigation bar thingy  */}
      {asideStatus && hasTouchButton && isModernDesign && !editor && (
        <>
          <motion.div className="aside-drag-area" ref={constraintsRef} />
          <motion.div
            className="aside-touch"
            drag="x"
            dragConstraints={constraintsRef}
            dragElastic={0.1}
            onClick={() => x.set(x.get() === 0 ? asideWidthWithSpace : 0)}
            style={{ x, zIndex: 1 }}
            whileDrag={{ scale: 1.2 }}
            whileHover={{ scale: 1.1 }}
          />
        </>
      )}
    </>
  );
};

export default Aside;
