import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { ThemeContextProvider } from "./contexts/themeContext";
import { AuthContextProvider } from "./contexts/AuthContext";
import reportWebVitals from "./reportWebVitals";
import App from "./App/App";
import "./styles/styles.scss";

const queryClient = new QueryClient();

ReactDOM.render(
  <Router>
    <React.StrictMode>
      <AuthContextProvider>
        <ThemeContextProvider>
          <QueryClientProvider client={queryClient}>
            <App />
            {/* <ReactQueryDevtools initialIsOpen={false} /> */}
          </QueryClientProvider>
        </ThemeContextProvider>
      </AuthContextProvider>
    </React.StrictMode>
  </Router>,
  document.getElementById("root")
);

reportWebVitals();
