export const withoutAsidePages = {
  pages: {
    id: "pages",
    text: "Pages",
    icon: "Extension",
  },
  auth: {
    id: "auth",
    text: "Auth Pages",
    icon: "Extension",
  },
  login: {
    id: "login",
    text: "Login",
    path: "auth/login",
    icon: "Login",
  },
  signUp: {
    id: "signUp",
    text: "Sign Up",
    path: "auth/signup",
    icon: "PersonAdd",
  },
  page404: {
    id: "Page404",
    text: "404 Page",
    path: "404",
    icon: "ReportGmailerrorred",
  },
  onBoarding: {
    id: "onBoarding",
    text: "On Boarding",
    path: "on-boarding",
    icon: "Summarize",
  },
  sendOtp: {
    id: "sendOtp",
    text: "Send OTP",
    path: "send-otp",
    icon: "AdminPanelSettings",
  },
};

export const dashboardMenu = {
  dashboard: {
    id: "dashboard",
    text: "Dashboard",
    path: "/",
    icon: "Dashboard",
    subMenu: null,
  },
};

export const asidePagesMenu = {
  //**********************************************************************
  //************ICONS REFERENCED WE CAN USE ARE (a -> z):*****************
  // AutoStories | Badge | Book | CalendarViewMonth |Contacts
  // EditCalendar | Event | Extension | FactCheck | Login
  // PersonAdd | PersonSearch | PublishedWithChanges | RecentActors
  // Today | QrCode2 | Store
  //**********************************************************************
  clients: {
    id: "clients",
    text: "Clients",
    path: "/clients",
    icon: "People",
  },
  projectsList: {
    id: "list",
    text: "Projects",
    path: "projects",
    icon: "AutoStories",
  },
  projectsListDesigner: {
    id: "listDesigner",
    text: "Projects",
    path: "designer/projects",
    icon: "AutoStories",
  },
  page1: {
    id: "p1",
    text: "Page 1",
    path: "/page-1",
    icon: "Today",
  },
  page2: {
    id: "p2",
    text: "Page 2",
    path: "/page-2",
    icon: "AutoStories",
  },
  page3: {
    id: "p3",
    text: "Page 3",
    path: "/page-3",
    icon: "Store",
    subMenu: {
      subPage1: {
        id: "sp1",
        text: "Sub Page 1 ",
        path: "/sub-page-1",
        icon: "FactCheck",
      },
      subPage2: {
        id: "sp2",
        text: "Sub Page 2 ",
        path: "/sub-page-2",
        icon: "CalendarViewMonth",
      },
      subPage3: {
        id: "sp3",
        text: "Sub Page 3 ",
        path: "/sub-page-3",
        icon: "QrCode2",
      },
    },
  },
};

export const designerMenu = {
  // clients: {
  //   id: "clients",
  //   text: "Clients",
  //   path: "/clients",
  //   icon: "People",
  // },
  projectsListDesigner: {
    id: "listDesigner",
    text: "Designer Projects",
    path: "/designer/projects",
    icon: "AutoStories",
  },
  assetLibrary: {
    id: "assetLibrary",
    text: "Asset Library",
    path: "/designer/asset-library",
    icon: "LocalLibrary",
  },
  compressor: {
    id: "compressor",
    text: "Model Compressor",
    path: "/designer/compressor",
    icon: "Compress",
  },
};

export const clientMenu = {
  projectsList: {
    id: "list",
    text: "Projects",
    path: "projects",
    icon: "AutoStories",
  },
  assetLibrary: {
    id: "assetLibrary",
    text: "Asset Library",
    path: "/all-models",
    icon: "LocalLibrary",
  },
  // browseTemplates: {
  //   id: "browseTemplates",
  //   text: "Templates",
  //   path: "/browse-templates",
  //   icon: "LayoutTextWindowReverse",
  // },
};

export const adminMenu = {
  users: {
    id: "users",
    text: "Users",
    path: "/users",
    icon: "People",
    subMenu: {
      // accountManagers: {
      //   id: "accountManagers",
      //   text: "Account Managers",
      //   path: "/account-managers",
      //   icon: "ManageAccounts",
      // },
      // developers: {
      //   id: "developers",
      //   text: "Developers",
      //   path: "/developers",
      //   icon: "Code",
      // },
      employees: {
        id: "employees",
        text: "Employees",
        path: "/employees",
        icon: "ManageAccounts",
      },
      designers: {
        id: "designers",
        text: "Designers",
        path: "/designers",
        icon: "DesignServices",
      },
      clients: {
        id: "clients",
        text: "Clients",
        path: "/clients",
        icon: "People",
      },
    },
  },
  projectsList: {
    id: "list",
    text: "Projects",
    path: "projects",
    icon: "AutoStories",
  },
};

export const accountManagerMenu = {
  clients: {
    id: "clients",
    text: "Clients",
    path: "/clients",
    icon: "People",
  },
  projectsList: {
    id: "list",
    text: "Projects",
    path: "projects",
    icon: "AutoStories",
  },
};

export const developerMenu = {
  clients: {
    id: "clients",
    text: "Clients",
    path: "/clients",
    icon: "People",
  },
  projectsList: {
    id: "list",
    text: "Projects",
    path: "projects",
    icon: "AutoStories",
  },
};

export const withAsidePages = {
  navbar: {
    id: "navbar",
    text: "Navigation Bar",
    path: "/",
    icon: "Page",
  },
};

export const pages = {
  dashboard: {
    id: "dashboard",
    text: "Dashboard",
    path: "/",
    icon: "Dashboard",
  },
  accountManagers: {
    id: "accountManagers",
    text: "Account Managers",
    path: "/account-managers",
    icon: "ManageAccounts",
  },
  developers: {
    id: "developers",
    text: "Developers",
    path: "/developers",
    icon: "Code",
  },
  employees: {
    id: "employees",
    text: "Employees",
    path: "/employees",
    icon: "ManageAccounts",
  },
  designers: {
    id: "designers",
    text: "Designers",
    path: "/designers",
    icon: "DesignServices",
  },
  clients: {
    id: "clients",
    text: "Clients",
    path: "/clients",
    icon: "People",
  },
  project: {
    id: "project",
    text: "Project",
    path: "/project",
    icon: "AutoStories",
  },
  projectsList: {
    id: "projects",
    text: "All Projects",
    path: "/projects",
    icon: "AutoStories",
  },
  projectDetails: {
    id: "project",
    text: "Project",
    path: "/project/:id",
    icon: "AutoStories",
  },
  assetLibrary: {
    id: "assetLibrary",
    text: "Asset Library",
    path: "/project/asset-library",
    icon: "LocalLibrary",
  },
  assetLibraryDetails: {
    id: "assetLibrary",
    text: "Asset Library",
    path: "/project/asset-library/:projectId",
    icon: "LocalLibrary",
  },
  product: {
    id: "product",
    text: "Product",
    path: "product/:id",
    icon: "Extension",
  },
  profile: {
    id: "profile",
    text: "Profile",
    path: "/user",
    icon: "AccountCircle",
  },
  editProfile: {
    id: "editProfile",
    text: "Edit Profile",
    path: "/edit-profile",
    icon: "Edit",
  },
  browseTemplates: {
    id: "browseTemplates",
    text: "Browse Templates",
    path: "/browse-templates",
    icon: "LayoutTextWindowReverse",
  },
  blank: {
    id: "blank",
    text: "Blank Page",
    path: "blank-page",
    icon: "check_box_outline_blank",
  },
  content: {
    id: "content",
    text: "Content Page",
    path: "content-page",
    icon: "WebAsset",
  },
};
