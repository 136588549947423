import React from "react";
import { withoutAsidePages } from "../menu";
// import DashboardHeader from "../pages/common/Headers/DashboardHeader";
import DefaultHeader from "../pages/common/Headers/DefaultHeader";

const headers = [
  { path: withoutAsidePages.login.path, element: null, exact: true },
  { path: withoutAsidePages.signUp.path, element: null, exact: true },
  { path: withoutAsidePages.page404.path, element: null, exact: true },
  { path: withoutAsidePages.sendOtp.path, element: null, exact: true },
  { path: withoutAsidePages.onBoarding.path, element: null, exact: true },
  {
    path: `*`,
    element: <DefaultHeader />,
  },
];

export default headers;
