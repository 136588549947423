import React, { lazy } from "react";
import {
  withoutAsidePages,
  asidePagesMenu,
  pages,
  clientMenu,
  designerMenu,
} from "../menu";
import Login from "../pages/auth/Login";

const AUTH = {
  PAGE_404: lazy(() => import("../pages/404/Page404")),
  PROTECTEDROUTE: lazy(() => import("../pages/auth/ProtectedRoute")),
};

const PAGES = {
  BLANK: lazy(() => import("../pages/page-layouts/Blank")),
  CONTENT: lazy(() => import("../pages/page-layouts/OnlyContent")),
  ONBOARDING: lazy(() => import("../pages/profile/onBoarding")),
  SENDOTP: lazy(() => import("../pages/auth/SendOtp")),
  EMPLOYEESLIST: lazy(() => import("../pages/users/Developers")),
  CLIENTSLIST: lazy(() => import("../pages/users/Clients")),
  DESIGNERSLIST: lazy(() => import("../pages/users/Designers")),
  DEVELOPERSLIST: lazy(() => import("../pages/users/Developers")),
  PROJECTMANAGEMENTLIST: lazy(() =>
    import("../pages/project-management/ProjectManagementsList")
  ),
  PROJECTSLISTDESIGNER: lazy(() =>
    import("../pages/project-management/ProjectsListDesigner")
  ),
  PROJECTMANAGEMENT: lazy(() =>
    import("../pages/project-management/ProjectManagement")
  ),
  ASSETLIBRARY: lazy(() => import("../pages/project-management/AssetLibrary")),
  DESIGNERASSETLIBRARY: lazy(() =>
    import("../pages/project-management/AllAssetLibrary")
  ),
  ALLASSETLIBRARY: lazy(() =>
    import("../pages/project-management/AllAssetLibrary")
  ),
  PRODUCT: lazy(() => import("../pages/project-management/ProductDetails")),
  COMPRESSOR: lazy(() => import("../pages/3Dcompressor/3dCompressor")),
  BROWSETEMPLATES: lazy(() =>
    import("../pages/ConfiguratorTemplates/BrowseTemplates")
  ),
  EDITPROFILE: lazy(() => import("../pages/profile/EditUserProfile")),
};

const allPages = [
  /**
   * Auth Page
   */

  {
    path: withoutAsidePages.page404.path,
    element: <AUTH.PAGE_404 />,
    exact: true,
  },
  {
    path: withoutAsidePages.login.path,
    element: <Login />,
    exact: true,
  },
  {
    path: withoutAsidePages.signUp.path,
    element: <Login isSignUp />,
    exact: true,
  },
  {
    path: withoutAsidePages.sendOtp.path,
    element: (
      <AUTH.PROTECTEDROUTE roles={["Admin", "Developer"]} verifing>
        <PAGES.SENDOTP />
      </AUTH.PROTECTEDROUTE>
    ),
    exact: true,
  },
  {
    path: withoutAsidePages.onBoarding.path,
    element: (
      <AUTH.PROTECTEDROUTE roles={[]} boarding>
        <PAGES.ONBOARDING />
      </AUTH.PROTECTEDROUTE>
    ),
    exact: true,
  },
  {
    path: pages.clients.path,
    element: (
      <AUTH.PROTECTEDROUTE roles={["Admin", "Developer"]}>
        <PAGES.CLIENTSLIST />
      </AUTH.PROTECTEDROUTE>
    ),
    exact: true,
  },
  {
    path: pages.developers.path,
    element: (
      <AUTH.PROTECTEDROUTE roles={["Admin", "Developer"]}>
        <PAGES.DEVELOPERSLIST />
      </AUTH.PROTECTEDROUTE>
    ),
    exact: true,
  },
  {
    path: pages.employees.path,
    element: (
      <AUTH.PROTECTEDROUTE roles={["Admin", "Developer"]}>
        <PAGES.EMPLOYEESLIST />
      </AUTH.PROTECTEDROUTE>
    ),
    exact: true,
  },
  {
    path: pages.designers.path,
    element: (
      <AUTH.PROTECTEDROUTE roles={["Admin", "Developer"]}>
        <PAGES.DESIGNERSLIST />
      </AUTH.PROTECTEDROUTE>
    ),
    exact: true,
  },
  {
    path: pages.dashboard.path,
    element: (
      <AUTH.PROTECTEDROUTE roles={["Client", "Designer", "Admin", "Developer"]}>
        <PAGES.CONTENT />
      </AUTH.PROTECTEDROUTE>
    ),
    exact: true,
  },
  {
    path: pages.projectsList.path,
    element: (
      <AUTH.PROTECTEDROUTE roles={["Client", "Designer", "Admin", "Developer"]}>
        <PAGES.PROJECTMANAGEMENTLIST />
      </AUTH.PROTECTEDROUTE>
    ),
    exact: true,
  },
  {
    path: asidePagesMenu.projectsListDesigner.path,
    element: (
      <AUTH.PROTECTEDROUTE roles={["Client", "Designer", "Admin", "Developer"]}>
        <PAGES.PROJECTSLISTDESIGNER />
      </AUTH.PROTECTEDROUTE>
    ),
    exact: true,
  },
  {
    path: pages.projectDetails.path,
    element: (
      <AUTH.PROTECTEDROUTE roles={["Client", "Designer", "Admin", "Developer"]}>
        <PAGES.PROJECTMANAGEMENT />
      </AUTH.PROTECTEDROUTE>
    ),
    exact: true,
  },
  {
    path: pages.assetLibraryDetails.path,
    element: (
      <AUTH.PROTECTEDROUTE roles={["Client", "Designer", "Admin", "Developer"]}>
        <PAGES.ASSETLIBRARY />
      </AUTH.PROTECTEDROUTE>
    ),
    exact: true,
  },
  {
    path: clientMenu.assetLibrary.path,
    element: (
      <AUTH.PROTECTEDROUTE roles={["Client", "Designer", "Admin", "Developer"]}>
        <PAGES.ALLASSETLIBRARY />
      </AUTH.PROTECTEDROUTE>
    ),
    exact: true,
  },
  {
    path: designerMenu.assetLibrary.path,
    element: (
      <AUTH.PROTECTEDROUTE roles={["Client", "Designer", "Admin", "Developer"]}>
        <PAGES.DESIGNERASSETLIBRARY />
      </AUTH.PROTECTEDROUTE>
    ),
    exact: true,
  },
  {
    path: pages.product.path,
    element: (
      <AUTH.PROTECTEDROUTE roles={["Client", "Designer", "Admin", "Developer"]}>
        <PAGES.PRODUCT />
      </AUTH.PROTECTEDROUTE>
    ),
    exact: true,
  },
  {
    path: pages.browseTemplates.path,
    element: (
      <AUTH.PROTECTEDROUTE roles={["Client", "Designer", "Admin", "Developer"]}>
        <PAGES.BROWSETEMPLATES />
      </AUTH.PROTECTEDROUTE>
    ),
    exact: true,
  },
  {
    path: designerMenu.compressor.path,
    element: (
      <AUTH.PROTECTEDROUTE roles={["Client", "Designer", "Admin", "Developer"]}>
        <PAGES.COMPRESSOR />
      </AUTH.PROTECTEDROUTE>
    ),
    exact: true,
  },
  {
    path: pages.editProfile.path,
    element: (
      <AUTH.PROTECTEDROUTE roles={["Client", "Designer", "Admin", "Developer"]}>
        <PAGES.EDITPROFILE />
      </AUTH.PROTECTEDROUTE>
    ),
    exact: true,
  },
  {
    path: pages.blank.path,
    element: <PAGES.BLANK />,
    exact: true,
  },
  {
    path: pages.content.path,
    element: <PAGES.CONTENT />,
    exact: true,
  },
];

const contents = [...allPages];

export default contents;
