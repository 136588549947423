// React Essentials
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import classNames from "classnames";

// Layouts
import PageWrapper from "./../../layout/PageWrapper/PageWrapper";
import Page from "./../../layout/Page/Page";
// Bootstrap
import Card, { CardBody } from "./../../components/bootstrap/Card";
import Checks, { ChecksGroup } from "./../../components/bootstrap/forms/Checks";
import FormGroup from "./../../components/bootstrap/forms/FormGroup";
import Input from "./../../components/bootstrap/forms/Input";
import Button from "./../../components/bootstrap/Button";
// Components
import useDarkMode from "./../../hooks/useDarkMode";
import AuthContext from "../../contexts/AuthContext";

import LogoLight from "../../assets/logos/ModularWideLogo_Inverted.png";
import LogoDark from "../../assets/logos/logoModularcx.webp";
import showNotification from "../../components/extras/showNotification";
import Icon from "../../components/icon/Icon";

// eslint-disable-next-line react/prop-types
const LoginHeader = ({ isNewUser }) => {
  if (isNewUser) {
    return (
      <>
        <div className="text-center h1 fw-bold mt-5">Create Account,</div>
        <div className="text-center h4 text-muted mb-5">
          Sign up to get started!
        </div>
      </>
    );
  }
  return (
    <>
      <div className="text-center h1 fw-bold mt-5">Welcome,</div>
      <div className="text-center h4 text-muted mb-5">Login to continue!</div>
    </>
  );
};

const LoginForm = () => {
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const validate = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = "Required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
    }

    const passwordRegex = /(?=.*[0-9])/;
    if (!values.password) {
      errors.password = "Required";
    } else if (values.password.length < 8) {
      errors.password = "Must be 8 characters long";
    } else if (!passwordRegex.test(values.password)) {
      errors.password = "*Must contain one number";
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validate,
    onSubmit: async (values) => {
      setIsLoading(true);
      const toast = await login(values);
      if (toast.status === 200) {
        showNotification(
          <span className="d-flex align-items-center">
            <Icon icon="Success" size="lg" className="me-1" />
            <span>{toast.title}</span>
          </span>,
          toast.description,
          "success"
        );
      } else {
        showNotification(
          <span className="d-flex align-items-center">
            <Icon icon="Danger" size="lg" className="me-1" />
            <span>{toast.title}</span>
          </span>,
          toast.description,
          "danger"
        );
        formik.resetForm();
      }
      navigate(toast.navigation);
      setIsLoading(false);
    },
  });

  return (
    <form id="login" className="row g-4" onSubmit={formik.handleSubmit}>
      <div className="col-12">
        <FormGroup id="email" isFloating label="Email">
          <Input
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            isValid={formik.isValid}
            showValid={false}
            isTouched={formik.touched.email}
            invalidFeedback={formik.errors.email}
          />
        </FormGroup>
      </div>
      <div className="col-12">
        <FormGroup id="password" isFloating label="Password">
          <Input
            type="password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            isValid={formik.isValid}
            showValid={false}
            isTouched={formik.touched.password}
            invalidFeedback={formik.errors.password}
          />
        </FormGroup>
      </div>
      <div className="col-12">
        <Button
          type="submit"
          color="primary"
          className="w-100 py-3"
          isDisable={isLoading}
        >
          {isLoading ? "Loading..." : "Login"}
        </Button>
      </div>
    </form>
  );
};

const SignupForm = () => {
  const { signup } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const validate = (values) => {
    const errors = {};
    if (!values.first_name) {
      errors.first_name = "Required";
    } else if (values.first_name.length > 15) {
      errors.first_name = "Must be 15 characters or less";
    }

    if (!values.last_name) {
      errors.last_name = "Required";
    } else if (values.last_name.length > 20) {
      errors.last_name = "Must be 20 characters or less";
    }

    if (!values.username) {
      errors.username = "Required";
    } else if (values.username.length < 3) {
      errors.username = "Must be at least 3 characters";
    }

    if (!values.email) {
      errors.email = "Required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
    }

    const passwordRegex = /(?=.*[0-9])/;
    if (!values.password) {
      errors.password = "Required";
    } else if (values.password.length < 8) {
      errors.password = "Must be 8 characters long";
    } else if (!passwordRegex.test(values.password)) {
      errors.password = "*Must contain one number";
    }

    // if (!values.role) {
    //   errors.role = "Must choose one before signing up";
    // }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      username: "",
      email: "",
      password: "",
      // role: "",
    },
    validate,
    onSubmit: async (values) => {
      setIsLoading(true);
      let result = await signup(values);
      if (result.status === 200) {
        showNotification(
          <span className="d-flex align-items-center">
            <Icon icon="VerifiedUser" size="lg" className="me-1" />
            <span>{result.title}</span>
          </span>,
          result.description,
          "success"
        );
      } else {
        showNotification(
          <span className="d-flex align-items-center">
            <Icon icon="VerifiedUser" size="lg" className="me-1" />
            <span>{result.title}</span>
          </span>,
          result.description,
          "danger"
        );
      }
      navigate(result.navigation);
      setIsLoading(true);
    },
  });

  return (
    <form id="signup" className="row g-4" onSubmit={formik.handleSubmit}>
      <div className="col-12 col-sm-6">
        <FormGroup id="first_name" isFloating label="First Name">
          <Input
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.first_name}
            isValid={formik.isValid}
            showValid={false}
            isTouched={formik.touched.first_name}
            invalidFeedback={formik.errors.first_name}
          />
        </FormGroup>
      </div>

      <div className="col-12 col-sm-6">
        <FormGroup id="last_name" isFloating label="Last Name">
          <Input
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.last_name}
            isValid={formik.isValid}
            showValid={false}
            isTouched={formik.touched.last_name}
            invalidFeedback={formik.errors.last_name}
          />
        </FormGroup>
      </div>

      <div className="col-12">
        <FormGroup id="username" isFloating label="Username">
          <Input
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.username}
            isValid={formik.isValid}
            showValid={false}
            isTouched={formik.touched.username}
            invalidFeedback={formik.errors.username}
          />
        </FormGroup>
      </div>

      <div className="col-12">
        <FormGroup id="email" isFloating label="Email">
          <Input
            type="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            isValid={formik.isValid}
            showValid={false}
            isTouched={formik.touched.email}
            invalidFeedback={formik.errors.email}
          />
        </FormGroup>
      </div>

      <div className="col-12">
        <FormGroup id="password" isFloating label="Password">
          <Input
            type="password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            isValid={formik.isValid}
            showValid={false}
            isTouched={formik.touched.password}
            invalidFeedback={formik.errors.password}
          />
        </FormGroup>
      </div>

      <div className="col-12">
        <Button
          type="submit"
          color="primary"
          className="w-100 py-3"
          isDisable={isLoading}
        >
          {isLoading ? "Loading..." : "Sign Up"}
        </Button>
      </div>
    </form>
  );
};

const Login = ({ isSignUp }) => {
  const { darkModeStatus } = useDarkMode();
  const [isNewUser, setIsNewUser] = useState(isSignUp);
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      showNotification(
        <span className="d-flex align-items-center">
          <Icon icon="VerifiedUser" size="lg" className="me-1" />
          <span>Already Logged In</span>
        </span>,
        "You're already logged in to your account."
      );
      navigate("/");
    }
  }, []);

  return (
    <PageWrapper
      title={isNewUser ? "Sign Up" : "Login"}
      className={"bg-primary"}
    >
      <Page className="p-0">
        <div className="row h-100 align-items-center justify-content-center">
          <div className="col-xl-4 col-lg-6 col-md-8 shadow-3d-container">
            <Card className="shadow-3d-dark" data-tour="login-page">
              <CardBody>
                <div className="text-center my-5">
                  <Link
                    to="/"
                    className={classNames(
                      "text-decoration-none fw-bold display-2",
                      {
                        "text-dark": !darkModeStatus,
                        "text-light": darkModeStatus,
                      }
                    )}
                  >
                    <img
                      alt="modular logo"
                      src={darkModeStatus ? LogoLight : LogoDark}
                      width={"80%"}
                      height={"auto"}
                    />
                  </Link>
                </div>

                {/* Login and Sign Up buttons to be added later
                <div
                  className={classNames("rounded-3", {
                    "bg-l10-dark": !darkModeStatus,
                    "bg-lo10-dark": darkModeStatus,
                  })}
                >
                  <div className="row row-cols-2 g-3 pb-3 px-3 mt-0">
                    <div className="col">
                      <Button
                        color={darkModeStatus ? "light" : "dark"}
                        isLight={!!isNewUser}
                        className="rounded-1 w-100"
                        size="lg"
                        onClick={() => {
                          setIsNewUser(!isNewUser);
                        }}
                      >
                        Login
                      </Button>
                    </div>
                    <div className="col">
                      <Button
                        color={darkModeStatus ? "light" : "dark"}
                        isLight={!isNewUser}
                        className="rounded-1 w-100"
                        size="lg"
                        onClick={() => {
                          setIsNewUser(!isNewUser);
                        }}
                      >
                        Sign Up
                      </Button>
                    </div>
                  </div>
                </div> */}
                <LoginHeader isNewUser={isNewUser} />

                {isNewUser ? <SignupForm /> : <LoginForm />}

                {/* BEGIN :: Social Login */}
                <>
                  <div className="col-12 mt-3 text-center text-muted">OR</div>
                  <div className="col-12 mt-3">
                    <Button
                      isOutline
                      color={darkModeStatus ? "light" : "dark"}
                      className={classNames("w-100 py-3", {
                        "border-light": !darkModeStatus,
                        "border-dark": darkModeStatus,
                      })}
                      icon="CustomApple"
                    >
                      Sign in with Apple
                    </Button>
                  </div>
                  <div className="col-12 mt-3">
                    <Button
                      isOutline
                      color={darkModeStatus ? "light" : "dark"}
                      className={classNames("w-100 py-3", {
                        "border-light": !darkModeStatus,
                        "border-dark": darkModeStatus,
                      })}
                      icon="CustomGoogle"
                    >
                      Continue with Google
                    </Button>
                  </div>
                </>
                {/* END :: Social Login */}
              </CardBody>
            </Card>
            <div className="text-center">
              <a href="/" className={"text-decoration-none me-3 link-light"}>
                Privacy policy
              </a>
              <a href="/" className={"text-decoration-none me-3 link-light"}>
                Terms of use
              </a>
            </div>
          </div>
        </div>
      </Page>
    </PageWrapper>
  );
};

Login.propTypes = {
  isSignUp: PropTypes.bool,
};
Login.defaultProps = {
  isSignUp: false,
};

export default Login;
