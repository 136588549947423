import axios from "axios";
import { createContext, useEffect, useState } from "react";
import jwt_decode from "jwt-decode";

const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [boarding, setBoarding] = useState(false);
  const [user, setUser] = useState(() => {
    if (
      localStorage.getItem("token") &&
      localStorage.getItem("token") !== "undefined"
    ) {
      let token = localStorage.getItem("token");
      let userData = jwt_decode(token);
      return userData;
    }
    return null;
  });

  const getUserInfo = async (token) => {
    const userDetails = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/user/getUserDetails`,
      {
        headers: {
          Cookies: `jwt=${token}`,
        },
      }
    );
    return userDetails.data.data;
  };

  const updateUser = async (data) => {
    setUser(data);
  };

  useEffect(async () => {
    if (!localStorage.getItem("token")) return;
    let data = await getUserInfo(
      localStorage.getItem("token").replace(/"/g, "")
    );
    setUser({ ...user, role: data.role.role, userData: { ...data } });
  }, [boarding, !user]);

  // signup function to add the info, get the token, store it in local storage, get user info and add it to state

  const login = async (payload) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/auth/login`,
        payload
      );
      localStorage.setItem("token", response.data.accessToken);
      localStorage.setItem("refresh", response.data.data.refreshToken);
      let userInfo = jwt_decode(response.data.accessToken);
      let userDetails = await getUserInfo(response.data.accessToken);
      setUser({ ...userInfo, userData: { ...userDetails } });
      return {
        status: 200,
        navigation: "/",
        title: "Login Successfully!",
        icon: "success",
        description: "You have been logged in successfully.",
      };
    } catch (error) {
      console.log(error);
      // if (response.status === 404) {
      //   return {
      //     status: 404,
      //     navigation: "/auth/login",
      //     title: response.data.,
      //     icon: "success",
      //     description: "You have been logged in successfully.",
      //   };
      // }
      if (error.response.status === 401) {
        return {
          status: 401,
          navigation: "/auth/login",
          title: "Incorrect Credinals!",
          icon: "danger",
          description: "Email or password is incorrect, please try again",
        };
      }
      if (error.response.status === 403) {
        localStorage.setItem("token", error.response.data.accessToken);
        localStorage.setItem("refresh", error.response.data.data.refreshToken);
        let userInfo = jwt_decode(error.response.data.accessToken);
        let userDetails = await getUserInfo(error.response.data.accessToken);
        setUser({ ...userInfo, userData: { ...userDetails } });
        resendOTP({
          userId: error.response.data.data._id,
          email: error.response.data.data.email,
        });
        return {
          status: 403,
          navigation: "/send-otp",
          title: "Verification Required!",
          icon: "info",
          description: "Please verify your account before proceeding.",
        };
      }
      // let data = await getUserInfo(
      //   localStorage.getItem("token").replace(/"/g, "")
      // );
      return {
        status: error.response.status,
        navigation: "/auth/login",
        title: "Something Went Wrong",
        icon: "danger",
        description: error.response.data.message,
      };
    }
  };

  const logout = async () => {
    // invoke the logout API call, for our NestJS API no logout API
    await axios.post(
      `${process.env.REACT_APP_BASE_URL}/auth/logout`,
      {},
      {
        headers: {
          Cookies: `jwt=${localStorage.getItem("token").replace(/"/g, "")}`,
        },
      }
    );
    localStorage.removeItem("token");
    localStorage.removeItem("refresh");
    setUser(null);
  };

  const signup = async (payload) => {
    try {
      const apiResponse = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/auth/signup`,
        payload
      );
      setUser({ userData: { ...apiResponse.data.data } });
      return {
        status: 200,
        navigation: "/send-otp",
        title: "Account Created Successfully!",
        icon: "success",
        description: "You have been create an new account successfully.",
      };
    } catch (err) {
      // console.log(err);
      if (err.response.status === 500) {
        return {
          status: 500,
          navigation: "/auth/signup",
          title: "Something Went Wrong!",
          icon: "danger",
          description: `${err.response.data.message}`,
        };
      }
    }
  };

  const verifyOTP = async (payload) => {
    try {
      const apiResponse = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/otp/verifyOTP`,
        payload
      );
      // add condition if not verified, redirect to /send-otp
      localStorage.setItem("token", apiResponse.data.accessToken);
      localStorage.setItem("refresh", apiResponse.data.data.refreshToken);
      let userInfo = jwt_decode(apiResponse.data.accessToken);
      let userDetails = await getUserInfo(apiResponse.data.accessToken);
      setUser({ ...userInfo, userData: { ...userDetails } });
      return {
        status: 200,
        navigation: "/on-boarding",
        title: "Verified Successfully!",
        icon: "success",
        description: "The OTP has been verified successfully!",
      };
    } catch (error) {
      return {
        status: 401,
        navigation: "/send-otp",
        title: "OTP Incorrect!",
        icon: "error",
        description: "The OTP inputed is incorrect!",
      };
    }
  };

  const resendOTP = async (payload) => {
    const apiResponse = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/otp/resendOTP`,
      payload
    );
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        updateUser,
        setBoarding,
        login,
        logout,
        signup,
        verifyOTP,
        resendOTP,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
