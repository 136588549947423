import * as React from "react";

function SvgAlignBottom(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="currentColor"
      className="svg-icon"
      viewBox="0 0 16 16"
      {...props}
    >
      <rect width={4} height={12} x={6} y={1} rx={1} />
      <path d="M1.5 14a.5.5 0 000 1v-1zm13 1a.5.5 0 000-1v1zm-13 0h13v-1h-13v1z" />
    </svg>
  );
}

export default SvgAlignBottom;
