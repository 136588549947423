// React Essentials
import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
// Dummy Users Model
// =========TO BE CHANGED TO MODEL CLASS============
import USERS from "../../common/data/userDummyData";
// =================================================
// Page key and values
import { pages, withoutAsidePages } from "../../menu";
// Bootstrap Components
import Button from "../../components/bootstrap/Button";
import Dropdown, {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "../../components/bootstrap/Dropdown";
// Hooks
import useDarkMode from "../../hooks/useDarkMode";
import AuthContext from "../../contexts/AuthContext";
import Icon from "../../components/icon/Icon";
import showNotification from "../../components/extras/showNotification";

// User's Profile Image
export const UserAvatar = ({ srcSet, src }) => {
  return (
    <div className="user-avatar">
      <img srcSet={srcSet} src={src} alt="Avatar" width={128} height={128} />
    </div>
  );
};
// Parameters for User's Profile Image
UserAvatar.propTypes = {
  src: PropTypes.string.isRequired,
  srcSet: PropTypes.string,
};
UserAvatar.defaultProps = {
  srcSet: null,
};

const User = () => {
  const navigate = useNavigate();
  const { darkModeStatus, setDarkModeStatus } = useDarkMode();

  const { user, logout } = useContext(AuthContext);

  const handleLogout = async () => {
    await logout();
    showNotification(
      <span className="d-flex align-items-center">
        <Icon icon="VerifiedUser" size="lg" className="me-1" />
        <span>Logout Successfully!</span>
      </span>,
      "You have been logged out successfully.",
      "success"
    );
    navigate(`/${withoutAsidePages.login.path}`);
  };
  return (
    <Dropdown>
      <DropdownToggle hasIcon={false}>
        <div className="user">
          <UserAvatar
            srcSet={user?.userData?.image_url || USERS.JOHN.srcSet}
            src={user?.userData?.image_url || USERS.JOHN.src}
          />
          <div className="user-info">
            <div className="user-name">{`${
              user?.userData?.first_name || ""
            } ${user?.userData?.last_name || ""}`}</div>
            <div className="user-sub-title">
              {user?.userData?.role?.role || ""}
            </div>
          </div>
        </div>
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem>
          <Button icon="AccountBox" onClick={() => navigate(pages.editProfile.path)}>
            Profile
          </Button>
        </DropdownItem>
        <DropdownItem>
          <Button
            icon={darkModeStatus ? "DarkMode" : "LightMode"}
            onClick={() => setDarkModeStatus(!darkModeStatus)}
            aria-label="Toggle fullscreen"
          >
            {darkModeStatus ? "Dark Mode" : "Light Mode"}
          </Button>
        </DropdownItem>
        <DropdownItem isDivider />
        <DropdownItem>
          <Button icon="Logout" onClick={handleLogout}>
            Logout
          </Button>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default User;
