import { useEffect, useState } from "react";
import axios from "axios";

export default function useGeoLocation() {
  const [geoLocation, setGeoLocation] = useState(null);

  const getGeoLocationAPI = async () => {
    await axios
      .get("https://ipapi.co/json/")
      .then((res) => {
        // console.log(res.data);
        // let data = res.data;
        setGeoLocation(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getGeoLocationAPI();
  }, []);

  return { geoLocation, getGeoLocationAPI };
}
